<template>
  <div class="d-flex flex-column justify-content-between" style="height: 100%">
    <router-link class="entrance-btn alert-danger" to="/sellerLogin">
      <i class="bi bi-clipboard-data"></i>
      <hr />
      <p>賣家登入</p>
    </router-link>
    <router-link class="entrance-btn alert-success" to="/customerLogin">
      <i class="bi bi-cart3"></i>
      <hr />
      <p>顧客登入</p>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.entrance-btn {
  font-size: 30px;
  font-weight: bolder;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  &:hover {
    box-shadow: 0px 0px 5px 2px gray;
  }
  @media screen and (max-width: 1100px) {
    height: 170px;
  }
  @media screen and (max-width: 1000px) {
    height: 130px;
  }
  @media screen and (max-width: 768px) {
    height: 170px;
    margin: 10px 0;
  }
}
</style>